<template>
    <b-card title="Demande de modification profil">
      
      <b>Utilisateur :</b><br />
      {{ profile_suggest.user.name }} {{ profile_suggest.user.surname }}

     

      <br /><br />
      <b>Date :</b><br />
      {{ profile_suggest.created_at }}

      <br /><br />
      <br />
      <h3>Modifications demandées :</h3> <br />
     
      <div v-if="profile_suggest.bio">
        <b>Biographie :</b><br />
        {{ profile_suggest.bio }}

        <br /><br />
      </div>

      <div v-if="profile_suggest.name">
        <b>Nom :</b><br />
        {{ profile_suggest.name }}

        <br /><br />
      </div>

      <div v-if="profile_suggest.surname">
        <b>Prénom :</b><br />
        {{ profile_suggest.surname }}

        <br /><br />
      </div>

      <div v-if="profile_suggest.brand_name">
        <b>Nom de marque :</b><br />
        {{ profile_suggest.brand_name }}

        <br /><br />
      </div>

      <div v-if="profile_suggest.email">
        <b>Email :</b><br />
        {{ profile_suggest.email }}

        <br /><br />
      </div>

      <div v-if="profile_suggest.brand_logo">
        <b>Photo :</b><br />
        {{ profile_suggest.brand_logo }}

        <br /><br />
      </div>

      <div v-if="profile_suggest.linkedin">
        <b>Linkedin :</b><br />
        {{ profile_suggest.linkedin }}

        <br /><br />
      </div>

      <div v-if="profile_suggest.facebook">
        <b>Facebook :</b><br />
        {{ profile_suggest.facebook }}

        <br /><br />
      </div>

      <div v-if="profile_suggest.x">
        <b>X :</b><br />
        {{ profile_suggest.x }}

        <br /><br />
      </div>

      <div v-if="profile_suggest.instagram">
        <b>Instagram :</b><br />
        {{ profile_suggest.instagram }}

        <br /><br />
      </div>

      <div v-if="profile_suggest.linkedin">
        <b>Linkedin :</b><br />
        {{ profile_suggest.linkedin }}

        <br /><br />
      </div>
      <br /><br />
      <b-button v-b-modal.modal-success variant="success">Accepter</b-button>
      &nbsp;
      <b-button v-b-modal.modal-danger  variant="danger">Supprimer</b-button>

      <b-modal
        id="modal-success"
        cancel-variant="outline-secondary"
        cancel-title="Non"
        ok-variant="success"
        ok-title="Oui"
        @ok="acceptPayout()"
        modal-class="modal-success"
        centered
        title="Êtes-vous sûr ?"
      >
        <b-card-text>
          Faire la modification sur le profil de {{ profile_suggest.user.name }} {{ profile_suggest.user.surname }} ?
        </b-card-text>
      </b-modal>

      <b-modal
      id="modal-danger"
      cancel-variant="outline-secondary"
      cancel-title="Non"
      ok-variant="danger"
      ok-title="Oui"
      @ok="deletePromotion()"
      modal-class="modal-danger"
      centered
      title="Êtes-vous sûr ?"
    >
      <b-card-text>
        Attention, cette action est irréversible ! Êtes-vous sûr de vouloir supprimer cette demande ?
      </b-card-text>
    </b-modal>

    </b-card>
    
</template>

<script>
import {BModal, BButton, VBModal, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest,Auth} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton
  },
  data() {
    return {
      profile_suggest:null,
      loaded:false,
      selectedItem:null
    }
  },
  methods:{
    async acceptPayout(){
      console.log('yess')
      this.loading=true
      try {

        let datas={}

        if(this.profile_suggest.bio)
        {
          datas.biography=this.profile_suggest.bio
        }
        if(this.profile_suggest.name)
        {
          datas.name=this.profile_suggest.name
        }
        if(this.profile_suggest.surname)
        {
          datas.surname=this.profile_suggest.surname
        }
        if(this.profile_suggest.email)
        {
          datas.email=this.profile_suggest.email
        }

        if(this.profile_suggest.brand_name)
        {
          datas.brand_name=this.profile_suggest.brand_name
        }

        if(this.profile_suggest.brand_logo)
        {
          datas.brand_logo=this.profile_suggest.brand_logo
        }

        if(this.profile_suggest.linkedin)
        {
          datas.linkedin=this.profile_suggest.linkedin
        }

        if(this.profile_suggest.facebook)
        {
          datas.facebook=this.profile_suggest.facebook
        }

        if(this.profile_suggest.x)
        {
          datas.x=this.profile_suggest.x
        }

        if(this.profile_suggest.instagram)
        {
          datas.instagram=this.profile_suggest.instagram
        }
        
        await Auth.update_account(this.profile_suggest.user.id, datas)

        await APIRequest.delete('profile-suggests',this.profile_suggest.id)
        
    
        
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'La modification a bien été effectuée !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.loading=false
          this.$router.push({name:'profile-suggests'})

      } catch (error) {
          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }
    },
    async deletePromotion()
    {
      this.loading=true
      try {
        await APIRequest.delete('profile-suggests',this.profile_suggest.id)
        
        this.loading=false
        this.$router.push({name:'profile-suggests'})
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'La demande a été supprimée !',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        
      } catch (error) {
        this.loading=false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Une erreur est survenue.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
        
      }
      
    }
  },
  async mounted()
  {
    this.profile_suggest=await APIRequest.detail('profile-suggests',this.$route.params.id)
    this.loaded=true
  }
}
</script>
